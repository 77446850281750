import React from 'react'
import Components from '../components/storyblok/components.js'
import Layout from "../components/layout";
import SEO from "../components/seo";

class StoryblokEntry extends React.Component {
    static getDerivedStateFromProps(props, state) {
        if (state.story.uuid === props.pageContext.story.uuid) {
            return null
        }

        return StoryblokEntry.prepareStory(props)
    }

    static prepareStory(props) {
        const story = Object.assign({}, props.pageContext.story)
        story.content = JSON.parse(story.content)

        return { story }
    }

    constructor(props) {
        super(props);
        this.state = StoryblokEntry.prepareStory(props)
    }

    render() {
        const story = this.state.story;
        const content = story.content;
        return (
            <>
                <SEO title={content.seo.title || story.name} description={content.seo.description} lang={'de'}/>
                <Layout title={story.name}>
                    <div content={content}>
                        {React.createElement(Components(content.component), {
                            title: story.name,
                            publishedAt: story.first_published_at,
                            key: content._uid,
                            blok: content
                        })}
                    </div>
                </Layout>
            </>
        )
    }
}

export default StoryblokEntry